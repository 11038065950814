<template>
	<div id="panel_announces_simple" class="panel announces">
		<div class="container">
			<div class="panel_header">
				<h2>Ближайшие мероприятия</h2>
			</div>
			<ul class="announce_list">
				<li
					v-for="(item, index) in fetchedData.data"
					v-if="item.fix_at_top"
					:key="item.id"
					class="main_event mb-4"
					:style="bgStyle(item, index)"
				>
					<intersect @enter.once="onVisible(item, index)">
						<div class="row">
							<div class="col-12 col-lg-2">
								<div class="announce_date_block border-lg-top border-lg-bottom">
									<div class="row">
										<div class="col-auto col-lg-12">
											<div class="announce_date_day">
												<span>{{ item.event_date | moment("DD") }}</span>
												<a
													:href="`//sk.skolkovo.ru/api/calendar/event/${item.id}`"
													class="add_announce"
												/>
											</div>
										</div>
										<div class="col-auto col-lg-12">
											<div class="announce_date_properties">
												<div class="announce_date_month">
													{{ item.event_date | moment("MMMM") }}
												</div>
												<div class="announce_date_time">
													{{ item.event_date | moment("HH:mm") }}
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="col-12 col-md-12 col-lg-8 offset-lg-2 pl-lg-0">
								<div class="announce_content_block border-lg-top border-bottom">
									<div class="announce_content_header">
										<!-- <div class="tag_string">
										<span>{{ item.type_titles.title_default }}</span>

										<span
											v-if="item.taxonomy.project"
										>{{ item.taxonomy.project.title }}</span>

										<span
											v-if="item.taxonomy.product"
										>{{ item.taxonomy.product.title }}</span>
									</div> -->

										<ul class="tag_list">
											<li>
												{{ item.type_titles.title_default }}
											</li>
											<li v-if="item.taxonomy && item.taxonomy.project">
												{{ item.taxonomy.project.title }}
											</li>
											<li v-for="section in item.taxonomy.sections" :key="section.title">
												<nuxt-link
													:to="$url.events({ query: { taxonomy_section: section.id } })"
												>
													{{ section.title }}
												</nuxt-link>
											</li>
										</ul>
									</div>
									<div class="announce_content_middle">
										<nuxt-link
											:to="$url.event(item.slug, item.typology_slug)"
											class="title announce_title"
										>
											{{ item.title }}
										</nuxt-link>
									</div>
								</div>
							</div>
						</div>
					</intersect>

					<div class="row">
						<div class="col-12 col-lg-8 offset-lg-4 pl-lg-0">
							<div class="announce_content_footer">
								<nuxt-link
									:to="$url.event(item.slug, item.typology_slug)"
									class="more_link"
								>
									Детали и регистрация
								</nuxt-link>
							</div>
						</div>
					</div>
				</li>
				<li
					v-for="item in fetchedData.data"
					v-if="!item.fix_at_top"
					:key="item.id"
				>
					<div class="row">
						<div class="col-12 col-lg-4">
							<div class="announce_date_block border-lg-bottom">
								<div class="row">
									<div class="col-auto">
										<div class="announce_date_day">
											<span>{{ item.event_date | moment("DD") }}</span>
											<a
												:href="'//sk.skolkovo.ru/api/calendar/event/' + item.id"
												class="add_announce"
												aria-label="Добавить в календарь"
											/>
										</div>
									</div>
									<div class="col-auto">
										<div class="announce_date_properties">
											<div class="announce_date_month">
												{{ item.event_date | moment("MMMM") }}
											</div>
											<div class="announce_date_time">
												{{ item.event_date | moment("HH:mm") }}
											</div>
											<!--<div class="announce_date_weekday">среда</div>-->
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="col-12 col-lg-8">
							<div class="announce_content_container border-bottom">
								<div class="row">
									<div class="col-12 col-lg-10">
										<div class="announce_content_block">
											<div class="announce_content_header">
												<ul class="tag_list">
													<li>
														{{ item.type_titles.title_default }}
													</li>
													<li v-if="item.taxonomy && item.taxonomy.project">
														{{ item.taxonomy.project.title }}
													</li>
													<li v-for="section in item.taxonomy.sections" :key="section.title">
														<nuxt-link
															:to="$url.events({ query: { taxonomy_section: section.id } })"
														>
															{{ section.title }}
														</nuxt-link>
													</li>
												</ul>
												<!--<div class="announce_reminder">
													<nuxt-link to="#">через X дней</nuxt-link>
												</div>-->
											</div>
											<div class="announce_content_middle">
												<nuxt-link
													:to="$url.event(item.slug, item.typology_slug)"
													class="title announce_title"
												>
													{{ item.title }}
												</nuxt-link>
												<p class="description">
													{{ item.subtitle }}
												</p>
											</div>
											<div class="announce_content_footer">
												<nuxt-link
													:to="$url.event(item.slug, item.typology_slug)"
													class="more_link"
												>
													Детали и регистрация
												</nuxt-link>
												<!--<div class="announce_content_footer_reminder">осталось XX дней</div>-->
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</li>
			</ul>
			<div class="panel_footer">
				<div class="row">
					<div class="col-12 col-md-4 col-lg-2">
						<nuxt-link to="/navigator/events/" class="btn btn-primary mb-4">
							Все мероприятия
						</nuxt-link>
					</div>
					<div class="col-12 col-md-8 col-lg-6 offset-lg-2">
						<div class="panel_footer_info">
							<div class="title">
								Не увидели подходящего мероприятия?
							</div>
							<p
								class="description"
							>
								Чтобы получить всю информацию об интересующем вас мероприятии,
								свяжитесь с нашим консультантом
							</p>
							<div class="btn-panel">
								<a
									id="announces_jivosite_top"
									onclick="jivo_api.open(); return false;"
									href="javascript:void(0)"
									class="btn btn-secondary-outer"
									role="button"
								>Чат с консультантом</a>
								<!--<a
									href="javascript:void(0)"
									onclick="ComagicWidget.openSitePhonePanel(); return false;"
									class="btn btn-secondary-outer"
								>Обратный звонок</a>-->
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Intersect from 'vue-intersect';

import '@/utils/moment';

export default {
	name: 'IssueAnnouncesSimple',
	components: {
		Intersect,
	},
	data() {
		return {
			fetchedData: [],
			observedImages: {},
		};
	},
	mounted() {
		this.$pagesApi.events({ per_page: 5, index_list: true })
			.then((response) => { this.fetchedData = response; });
	},
	methods: {
		onVisible(item, index) {
			if (item.listing_cover_image_url_default) {
				this.$set(this.observedImages, `listing_cover_image_url_default__${index}`, true);
			} else if (item.top_cover_image_url_wide) {
				this.$set(this.observedImages, `top_cover_image_url_wide__${index}`, true);
			}

    },
    bgStyle(item, index) {
      if (this.observedImages[`listing_cover_image_url_default__${index}`]) {
				return `background-image: url('${item.listing_cover_image_url_default}')`;
      } else if (this.observedImages[`top_cover_image_url_wide__${index}`]) {
				return `background-image: url('${item.top_cover_image_url_wide}')`;
			}
      return '';
    },
	},
};
</script>

<style lang="scss" scoped>
	@import "~/assets/styles/components/home/panel-announces-simple";
</style>
